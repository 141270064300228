export const features = [
  {
    id: 1,
    title: "Compartilhamos os benefícios",
    description: `Investimos nossos resultados para criar novas e
    melhores oportunidades de vida para nossos
    colaboradores, parceiros e a sociedade.`,
    link: "/",
  },
  {
    id: 2,
    title: "Nossos produtos são especiais",
    description: `Produzimos alimentos que são sempre fonte de bem estar, saúde e prazer para todos.`,
    link: "/",
  },
  {
    id: 3,
    title: "Como a gente faz importa",
    description: `Escolhemos tecnologia e processos que, além de eficientes, contribuem para melhorar a qualidade dos nossos produtos, o bem estar das pessoas e preservação do meio ambiente.`,
    link: "/",
  },
]
